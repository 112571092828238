import React from "react";
import Image from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/Seo";
import tslil from "../assets/images/artists/tslil/profile/tslil-artist-profile.jpg";

const Artist_Tslil = ({ data }) => (
  <Layout>
    <SEO
      title="Artist Tslil Tsemet"
      keywords={[`obsidian la`, `obsidian la tattoo`, `los angeles tattoo`]}
    />
    <div id="main" className="alt">
      <div className="inner">
        <header className="major">
          <h1>Tslil Tsemet</h1>
        </header>
        <div className="image">
          <img src={tslil} alt="tslil" className="image left" />
          <p>
            Tslil Tsemet is a fine artist and tattooer, she was born in Israel,
            studied art at the Bezalel Academy of Srt in Jerusalem, where many
            of here professors advised her to move to the U.S. for the art scene
            might be a better fit for her artwork. She started tattooing at 17,
            but made it to her main job 5 years ago. She is specialized in many
            different styles of tattoos, but her favorite at the moment is
            engraving tattoos (blackwork, linework) and realistic portraits of
            humans or animals. She has been and still is exhibiting her oil
            paintings and sculptures in many galleries and museums in Los
            Angeles, San Francisco, and in Tel-Aviv.{" "}
          </p>

          <hr />
          <h4 style={{ float: `left`, marginTop: `.3em` }}>
            <a
              href="https://www.instagram.com/the.girl.with.the.brushes/?hl=en"
              className="icon alt fa-instagram"
            >
              <span className="label">Instagram</span>
              &nbsp; @the.girl.with.the.brushes
            </a>
          </h4>
          <ul className="actions" style={{ float: `right` }}>
            <li>
              <a href="#contact" className="button next left scrolly fit">
                Booking
              </a>
            </li>
          </ul>
        </div>
      </div>
      <section
        className="tiles"
        style={{
          width: `100%`,
          justifyContent: `center`,
          margin: `-1em`,
          display: `block`
        }}
      >
        <Image className="image" fluid={data.file.childImageSharp.fluid} />
      </section>
    </div>
  </Layout>
);

export const data = graphql`
  query ArtistTslil {
    file(relativePath: { eq: "artists/tslil/work/tslil-001.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 293, maxWidth: 293, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default Artist_Tslil;
